import React from 'react';

import PropTypes from 'prop-types';

import ConnectedUsersBaseView from './UsersBaseView';

const UsersProfileView = (
  props
) => {

  return (
    <div>
      <ConnectedUsersBaseView
        {...props}
      >
      </ConnectedUsersBaseView>
    </div>
  );
}

UsersProfileView.propTypes = {

}

export default UsersProfileView;
