import React from 'react';

import PropTypes from 'prop-types';

import PublishingBaseView from './PublishingBaseView';

// import './PublishingArticleView.less';

const PublishingArticleView = (
  props
) => {

  return (
    <div>
      <PublishingBaseView
        {...props}
      >
      </PublishingBaseView>
    </div>
  );
}

PublishingArticleView.propTypes = {

}

export default PublishingArticleView;
