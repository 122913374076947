import React, { useState } from 'react';

import { connect } from "react-redux";

import PropTypes from 'prop-types';

import { navigate } from "gatsby";

import Tagsonomy from 'tagsonomy-library';

import Vowtch from 'vowtch-library-branch';

import config from '../../utils/config';

import './PublishingBaseView.less';

const {
  tagsonomyService
} = Tagsonomy.Services;

const {
  guestStorageGetItem,
  guestStorageSetItem
} = Vowtch.Hooks;

const {
  CardContainer,
  GenericCard,
  GenericForm,
  GenericHeader
} = Vowtch.Components;

const {
  creativecontentService,
  userService

} = Vowtch.Services;

const PublishingBaseView = (
  {
    carouselContent,
    data,
    formValues,
    onChangeTags,
    receiveData,
    receiveDataAlternate,
    receiveFormValues,
    receiveStaticData,
    secondaryStaticData,
    staticData,
    subject,
    ...props
  }
) => {

  const  {
    hasCardContainer,
    pages,
    title
  } = props;

  const [offset, setOffset] = useState(config.defaultSecondaryLimit[config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]);

  const handleDataSubmit = (
    submitHandler,
    values
  ) => {
    console.log(values);
    Vowtch.Services[submitHandler.service][submitHandler.action](values, receiveFormValues)
    guestStorageGetItem(
      userService.listUsers,
      config.defaultParameters,
      receiveStaticData,
      config.entity.user.useStateWithLocalStorageKey
    );
  }

  const handleDefaultParametersAlternate = () =>
    carouselContent ?
      Object.assign(
        {},
        ...Object.assign(
          {},
          ...config.defaultSecondaryLimit,
          {
            [config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]
            :
            offset
          }
        )
        ,
        ...config.defaultParameters,
        {
          [config.defaultThirdPartyThirdPartySecondaryField]
          :
          carouselContent
        }
      )
      :
      Object.assign(
        {},
        ...Object.assign(
          {},
          ...config.defaultSecondaryLimit,
          {
            [config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]
            :
            offset
          }
        )
        ,
        ...config.defaultParameters
      )
    ;

  const handleStaticData = () => {
    console.log(handleDefaultParametersAlternate());
    console.log(offset);
    setOffset(offset + config.defaultSecondaryLimit[config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]);
    guestStorageGetItem(
      userService.listUsers,
      config.defaultParameters,
      receiveStaticData,
      config.entity.user.useStateWithLocalStorageKey
    );
    ;
  }

  !staticData.length &&
    // userService.listUsers(config.defaultSecondaryParameters, receiveStaticData);
    guestStorageGetItem(
      userService.listUsers,
      config.defaultParameters,
      receiveStaticData,
      config.entity.user.useStateWithLocalStorageKey
    );

  console.log(secondaryStaticData)

  Array.isArray(secondaryStaticData) && !secondaryStaticData.length &&
    tagsonomyService.listTagsonomies(config.defaultThirdPartySecondaryParameters, onChangeTags);

  return (
    <div>
      {
        pages.filter(
          item => item.node.title === title
        )
          .map(
            ({ node: item, index }) =>
              [
                <GenericHeader
                  avatar={config.entity.user.publishingFeatures.avatar}
                  datatransfomation={item.datatransfomation}
                  hasButton
                  header={item.header}
                  inheritTitle={item.inheritTitle}
                  innerConfig={config}
                  innerPages={pages}
                  navigate={navigate}
                  requiredThirdPartyTitle={item.requiredThirdPartyTitle}
                  subject={subject}
                  thirdPartyTitle={item.thirdPartyTitle}
                  title={item.title}
                />,
                hasCardContainer &&
                  <CardContainer
                    card={
                      <GenericCard
                        datatransfomation={item.datatransfomation}
                        form={item.form}
                        grid={item.card.grid}
                        handleDataSubmit={handleDataSubmit}
                        hasButton
                        hasHeader
                        headerWidgets={item.card.headerWidgets}
                        handleStaticData={handleStaticData}
                        innerConfig={config}
                        innerPages={pages}
                        innerWidgets={item.card.innerWidgets}
                        navigate={navigate}
                        navigation={item.navigation}
                        sections={item.card.sections}
                        sideWidgets={item.card.sideWidgets}
                        staticData={staticData}
                        table={item.table}
                        text={item.text}
                        title={item.card.title}
                      />
                    }
                    innerConfig={config}
                    isMixinContent
                  />
              ]
          )
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeTags: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTags`
      }
    )
    ,
    receiveData: (payload) => dispatch (
      {
        payload,
        type: `receiveData`
      }
    )
    ,
    receiveDataAlternate: (payload) => dispatch (
      {
        payload,
        type: `receiveDataAlternate`
      }
    )
    ,
    receiveFormValues: (payload) => dispatch (
      {
        payload,
        type: `receiveFormValues`
      }
    )
    ,
    receiveStaticData: (payload) => dispatch (
      {
        payload,
        type: `receiveStaticData`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    carouselContent: state.carouselContent,
    data: state.data,
    formValues: state.formValues,
    secondaryStaticData: state.secondaryStaticData,
    staticData: state.staticData,
    subject: state.subject
  }
);

const ConnectedPublishingBaseView = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishingBaseView);

 export default ConnectedPublishingBaseView
