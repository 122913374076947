import React from 'react';

import { connect } from "react-redux";

import PropTypes from 'prop-types';

import Vowtch from 'vowtch-library-branch';

import config from '../../utils/config';

import './UsersBaseView.less';

const {
  CardContainer,
  GenericCard,
  GenericForm,
  GenericHeader
} = Vowtch.Components;

const { userService } = Vowtch.Services;

const UsersBaseView = (
  {
    alternateCardShown,
    showAlternateCard,
    receiveStaticData,
    staticData,
    subject,
    ...props
  }
) => {

  const  {
    hasCardContainer,
    pages,
    title
  } = props;

  const handleSubmit = (values) => {

  }

  const handleCardSwitch = (event, link) => {
    event.preventDefault();
    showAlternateCard();
  }

  !staticData.length &&
    userService.listUsers(config.defaultSecondaryParameters, receiveStaticData);

  return (
    <div>
      {
        pages.filter(
          item => item.node.title === title
        )
          .map(
            ({ node: item, index }) =>
              [
                <GenericHeader
                  handleCardSwitch={handleCardSwitch}
                  hasParent
                  innerConfig={config}
                  isEditable={item.isEditable}
                  staticData={staticData}
                  subject={subject}
                />
                ,
                hasCardContainer ?
                  <CardContainer
                    card={
                      alternateCardShown ?
                        <GenericForm
                          formFields={item.form.formFields}
                          grid={item.form.grid}
                          handleSubmit={handleSubmit}
                          hasFooter
                          hasHeader
                          innerConfig={config}
                          menu={item.form.menu}
                          submitLabel={item.form.submitLabel}
                          title={item.form.title}
                        />
                        :
                        <GenericCard
                          form={item.form}
                          grid={item.card.grid}
                          hasHeader
                          hasParent={item.card.hasParent}
                          innerConfig={config}
                          sections={item.card.sections}
                          sideWidgets={item.card.sideWidgets}
                          staticData={staticData}
                          title={item.card.title}
                        />
                    }
                    innerConfig={config}
                  >
                  </CardContainer>
                  :
                  <GenericForm
                    formFields={item.form.formFields}
                    grid={item.form.grid}
                    handleSubmit={handleSubmit}
                    hasFooter
                    hasHeader
                    innerConfig={config}
                    menu={item.form.menu}
                    submitLabel={item.form.submitLabel}
                    title={item.form.title}
                  />
              ]
          )
      }
    </div>
  );
}

UsersBaseView.propTypes = {

}

const mapDispatchToProps = dispatch => (
  {
    receiveStaticData: (payload) => dispatch (
      {
        payload,
        type: `receiveStaticData`
      }
    )
    ,
    showAlternateCard: () => dispatch (
      {
        type: `showAlternateCard`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    alternateCardShown: state.alternateCardShown,
    staticData: state.staticData,
    subject: state.subject
  }
);

const ConnectedUsersBaseView = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersBaseView);

 export default ConnectedUsersBaseView
