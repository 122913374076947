import React from 'react';

import { Router } from "@reach/router"

import { LocaleProvider } from 'antd';

// import { graphql } from "gatsby";

import VowtchLayout from '../components/layouts/index';
import VowtchLayoutAlternate from '../components/layouts/index-alternate';

import DashboardClassicView from '../components/dashboard/DashboardClassicView';
import PublishingContentTypesView from '../components/publishing/PublishingContentTypesView';
import PublishingArticleView from '../components/publishing/PublishingArticleView';
import PublishingDistributionView from '../components/publishing/PublishingDistributionView';
import PublishingLibraryView from '../components/publishing/PublishingLibraryView';
import PublishingManagementView from '../components/publishing/PublishingManagementView';
import PublishingPostView from '../components/publishing/PublishingPostView';
import PublishingPreferencesView from '../components/publishing/PublishingPreferencesView';
import PublishingSplashView from '../components/publishing/PublishingSplashView';
import SearchResultsView from '../components/directory/SearchResultsView';
import UsersCommunityPreferencesView from '../components/users/UsersCommunityPreferencesView';
import UsersContactView from '../components/users/UsersContactView';
import UsersProfileView from '../components/users/UsersProfileView';

import enUS from 'antd/lib/locale-provider/en_US';

const App = ({ data, history, location }) => {

  const allPagesJson = data.allPagesJson.edges;

  console.log()

  return (
    <VowtchLayoutAlternate
      location={location}
      pages={allPagesJson}
    >
      <Router>
        <DashboardClassicView
          pages={allPagesJson}
          path="/app/networks"
          title="Dashboard"
        />
        <PublishingArticleView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-article"
          title="Publish an Article"
        />
        <PublishingDistributionView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-distribution"
          title="Manage Distribution"
        />
        <PublishingContentTypesView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-content-types"
          title="Produce Content"
        />
        <PublishingLibraryView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-library"
          title="Studio Library"
        />
        <PublishingLibraryView
          hasCardContainer
          pages={allPagesJson}
          path="/app/networks/publishing-library"
          title="Studio Library"
        />
        <PublishingManagementView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-management"
          title="Content Management"
        />
        <PublishingPostView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-post"
          title="Quick Post"
        />
        <PublishingPreferencesView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-preferences"
          title="Publishing Preferences"
        />
        <PublishingSplashView
          hasCardContainer
          pages={allPagesJson}
          path="/app/publishing-splash"
          title="The Studio"
        />
        <PublishingSplashView
          hasCardContainer
          pages={allPagesJson}
          path="/app/networks/publishing-splash"
          title="Studio"
        />
        <SearchResultsView
          hasCardContainer
          pages={allPagesJson}
          path="/app/global-directory"
          title="Global Directory"
        />
        <SearchResultsView
          hasCardContainer
          pages={allPagesJson}
          path="/app/search-results"
          title="Community Directory"
        />
        <SearchResultsView
          hasCardContainer
          pages={allPagesJson}
          path="/app/networks/search-results"
          title="Directory"
        />
        <SearchResultsView
          hasCardContainer
          pages={allPagesJson}
          path="/app/networks/search-results/groups"
          title="Group Management"
        />
        <SearchResultsView
          hasCardContainer
          pages={allPagesJson}
          path="/app/networks/search-results/search"
          title="Group Community Directory"
        />
        <UsersCommunityPreferencesView
          hasCardContainer
          pages={allPagesJson}
          path="/app/networks/users-community-preferences"
          title="Group Community Preferences"
        />
        <UsersCommunityPreferencesView
          hasCardContainer
          pages={allPagesJson}
          path="/app/users-community-preferences"
          title="Community Preferences"
        />
        <UsersCommunityPreferencesView
          hasCardContainer
          pages={allPagesJson}
          path="/app/user-community-preferences-extended-secondary"
          title="Invite Friends To Vowtch"
        />
        <UsersCommunityPreferencesView
          hasCardContainer
          pages={allPagesJson}
          path="/app/user-community-preferences-extended"
          title="Community Settings"
        />
        <UsersContactView
          hasCardContainer
          pages={allPagesJson}
          path="/app/users-contact"
          title="Contact"
        />
        <UsersProfileView
          hasCardContainer
          pages={allPagesJson}
          path="/app/users-profile"
          title="Edit Profile"
        />
      </Router>
    </VowtchLayoutAlternate>
  );
}

export const query = graphql`
  query PagesAppQuery {
    allPagesJson {
      edges {
        node {
          card {
            grid {
              gridSpan
              gridSpanTreshold
            }
            headerWidgets {
              alternateHeaderWidget
              form {
                submitHandler {
                  action {
                    type
                  }
                }
              }
              grid {
                gridSpan
                gridSpanTreshold
              }
              inlineInformation {
                card
                field
                justify
                topic
              }
              requiredHeaderWidget
            }
            hasParent
            innerWidgets {
              alternateInnerWidget
              form {
                submitHandler {
                  action {
                    type
                  }
                }
              }
              grid {
                columns
                gridSpan
                gridSpanTreshold
              }
              inlineInformation {
                addonAfter {
                  avatar
                }
                addonBefore {
                  avatar
                }
                alternateTopic
                card
                customTopic
                customTopicyAlternate
                field
                href
                innerWidgetInnerWidgetReverse
                justify
                limit
                topic
              }
              justify
              requiredInnerWidget
              secondaryJustify
            }
            sections {
              avatar
              content
              field
              link
              title
            }
            sideWidgets {
              alternateSideWidget
              avatar
              avatarInformation {
                avatar
              }
              form
              grid {
                gridSpan
                gridSpanTreshold
              }
              inlineInformation {
                field
                menu {
                  title
                }
                topic
              }
              requiredSideWidget
              singleInformation
              topic
            }
            title
          }
          carousel {
            settings {
              autoplaySpeed
              dots
              effect
              image {
                pause
                play
              }
              speed
              static
              swipe
            }
          }
          cover {
            backgroundColor
            image {
              sizes {
                src
              }
              title
            }
          }
          datatransfomation {
            className
            defaultField
            extraMarkerField
            field
            markerField
          }
          form {
            actions {
              buttons {
                actionHandler {
                  condition {
                    leftHandSide
                    rightHandSide
                    value
                  }
                  field
                  href
                  link
                  payload
                  payloadExtended {
                    field
                    value
                  }
                  type
                }
                alternateButtonShown
                ascendant
                step
                title
              }
            }
            avatar
            cover {
              actionHandler {
                type
              }
              alternateCoverStep
              backgroundColor
              image {
                sizes {
                  src
                }
                title
              }
              justify
              progress {
                percent
                showInfo
                status
                strokeWidth
              }
              step
              steps {
                avatar
                meta {
                  description
                  replacementDescription {
                    text
                  }
                  title
                }
                step
              }
              topic
            }
            defaultFields {
              defaultValue
              field
              label
            }
            formFields {
              hidden
              rows {
                addonAfter {
                  avatar
                  button {
                    addonBefore {
                      avatar
                    }
                    label
                  }
                }
                addonBefore {
                  avatar
                }
                columns {
                  addonBefore {
                    avatar
                  }
                  button {
                    action {
                      payload
                      skipStep
                      type
                    }
                    addonBefore {
                      avatar
                    }
                    justify
                    label
                  }
                  buttonLabel
                  defaultValue
                  drawer
                  dynamic
                  externalStaticData {
                    titleField
                    valueField
                  }
                  field
                  grid {
                    gridSpan
                  }
                  hasAvatar
                  helper {
                    avatar
                    text {
                      text
                    }
                    title
                  }
                  hidden
                  image
                  internalStaticData
                  justify
                  label
                  link
                  secondaryInternalStaticData {
                    condition
                    title
                  }
                  select {
                    options {
                      title
                      value
                    }
                  }
                  status
                  subject
                  switch
                  tags {
                    closable
                    name
                  }
                  textarea
                }
                display
              }
              step
              title
            }
            grid {
              gridAddonAfterTreshold
              gridSpan
            }
            hasAlternateCover
            hasBaseButton
            hasCover
            hasHeader
            menu {
              items {
                title
              }
              steps
            }
            preview {
              datatransfomation {
                alternateField
                defaultField
                externalStaticDataField
                field
                markerField
                replacementExternalStaticDataField
                secondarExternalStaticDataField
                separatorText
                step
                switchField
                thirdPartyField
              }
              externalSubjectField
              field
              hasExtendedContent
              steps
              subject
              title
            }
            replacementTitle {
              avatar
              justify
              steps
              title
            }
            submitHandler {
              action
              service
              submitStep
            }
            submitHandlerAlternate {
              action
              service
              submitStep
            }
            submitHandlerThirdParty {
              action
              service
              submitStep
            }
            submitLabel
            text {
              extraMarkerFieldSlugText
            }
            title
          }
          header {
            action {
              payload
              type
            }
            avatar
            button {
              addonBefore {
                avatar
              }
              label
            }
            href
            justify
            secondaryButton {
              action {
                type
              }
              addonBefore {
                avatar
              }
              href
              label
            }
          }
          isEditable
          inheritTitle
          navigation {
            hrefFormHeaderClick
            hrefFormHeaderClickAlternate
            hrefInnerWidgetInnerClick
          }
          requiredThirdPartyTitle
          slug
          table {
            columns {
              dataIndex
              image
              textSorter
              title
            }
            field
          }
          text {
            activityResultAlternateSlugText
            activityResultSlugText
            extraMarkerFieldSlugText
          }
          thirdPartyTitle
          title
        }
      }
    }
  }
`

export default App
