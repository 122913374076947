import React from 'react';

import PropTypes from 'prop-types';

import UserSecondaryBaseView from './UserSecondaryBaseView';

import './UsersCommunityPreferencesView.less';

const UsersCommunityPreferencesView = (
  props
) => {

  return (
    <div>
      <UserSecondaryBaseView
        {...props}
      >
      </UserSecondaryBaseView>
    </div>
  );
}

UsersCommunityPreferencesView.propTypes = {

}

export default UsersCommunityPreferencesView;
