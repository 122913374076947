import React, { useState } from 'react';

import { connect } from "react-redux";

import PropTypes from 'prop-types';

import { navigate } from "gatsby";

import Tagsonomy from 'tagsonomy-library';

import Vowtch from 'vowtch-library-branch';

import VowtchStatic from 'vowtch-static';

import config from '../../utils/config';

import './SearchBaseView.less';

const {
  tagsonomyService
} = Tagsonomy.Services;

const {
  guestStorageGetItem,
  guestStorageSetItem
} = Vowtch.Hooks;

const {
  CardContainer,
  GenericCard,
  GenericForm,
  GenericHeader
} = Vowtch.Components;

const {
  groupService,
  userService
 } = Vowtch.Services;

const SearchBaseView = (
  {
    alternateCardInnerWidgetInnerWidgetShown,
    classieContentBadgeText,
    carouselContent,
    data,
    formValues,
    onChangeStep,
    onChangeTags,
    receiveData,
    receiveDataAlternate,
    receiveStaticData,
    secondaryMenu,
    secondaryStaticData,
    stateModified,
    staticData,
    step,
    ...props
  }
) => {

  const  {
    hasCardContainer,
    pages,
    title
  } = props;

  const [offset, setOffset] = useState(config.defaultSecondaryLimit[config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]);

  console.log(carouselContent && carouselContent != config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField]);
  console.log(config.defaultThirdPartyParameters);

  const handleData = (
    datatransfomation = pages.filter(
      item => item.node.title === title
    ).slice().shift().node.datatransfomation
  ) =>
    data.length &&
    datatransfomation &&
    data.slice().shift()[
      datatransfomation.find(
        element => element.extraMarkerField
      ).field
    ]
  ;

  const handleDefaultParameters = (defaultParameters) =>
    (carouselContent && carouselContent !== config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField]) ?
      Object.assign(
        {},
        // ...config.defaultSecondaryLimit,
        ...config.defaultParameters
        ,
        {
          [config.defaultThirdPartyThirdPartySecondaryField]
          :
          carouselContent
        }
      )
      :
      Object.assign(
        {},
        // ...config.defaultSecondaryLimit,
        ...defaultParameters
      )
    ;

  const handleDefaultParametersAlternate = (defaultParameters) =>
    // carouselContent && carouselContent !== config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] ?
    classieContentBadgeText && classieContentBadgeText.length ?
      Object.assign(
        {},
        ...Object.assign(
          {}
          // ...config.defaultSecondaryLimit,
          // {
          //   [config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]
          //   :
          //   offset
          // }
        )
        ,
        ...config.defaultParameters,
        {
          [config.defaultThirdPartyThirdPartySecondaryField]
          :
          carouselContent
        }
      )
      :
      Object.assign(
        {},
        ...Object.assign(
          {}
          // ...config.defaultSecondaryLimit
          // {
          //   [config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]
          //   :
          //   offset
          // }
        )
        ,
        defaultParameters,
      )
    ;

  console.log(handleDefaultParametersAlternate(config.defaultParametersAlternate));
  console.log(handleDefaultParametersAlternate(config.defaultParameters));

  const handleDefaultSecondaryParameters = () =>
    Object.assign(
      {},
      ...config.defaultLimit,
      ...config.defaultSecondaryParameters
    )
  ;


  const handleStaticData = () => {
    console.log(handleDefaultParametersAlternate(config.defaultParametersAlternate));
    console.log(handleDefaultParametersAlternate(config.defaultParameters));
    console.log(offset);
    setOffset(offset + config.defaultSecondaryLimit[config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate]);
    alternateCardInnerWidgetInnerWidgetShown ?
      groupService.listGroups(handleDefaultParametersAlternate(config.defaultParametersAlternate), receiveDataAlternate)
      :
      userService.listUsers({...handleDefaultParametersAlternate(config.defaultParameters), ...formValues}, receiveDataAlternate)
    ;
  }

  const handleTitle = (
    item = pages.filter(
      item => item.node.title === title
    ).slice().shift().node
  ) =>
    item.inheritTitle ?
      secondaryMenu ?
        secondaryMenu.items ?
          secondaryMenu.items.find(
            element => element.title.includes(item.card.title)
          ) ?
            secondaryMenu.items.find(
              element => element.title.includes(item.card.title)
            ).title
            :
            item.card.title
          :
          item.card.title
        :
        item.card.title
      :
      item.card.title
    ;

  !staticData.length &&
    // userService.listUsers(handleDefaultSecondaryParameters(), receiveStaticData);
    guestStorageGetItem(
      userService.listUsers,
      config.defaultParameters,
      receiveStaticData,
      config.entity.user.useStateWithLocalStorageKey
    );

  alternateCardInnerWidgetInnerWidgetShown ?
    (
      !data.length
      ||
      !handleData()
    ) &&
    groupService.listGroups(handleDefaultParametersAlternate(config.defaultParametersAlternate), receiveData)
    :
    (
      !data.length
      // ||
      // !!stateModified !== stateModified || stateModified
    ) &&
    userService.listUsers({...handleDefaultParametersAlternate(config.defaultParameters), ...formValues}, receiveData)
  ;

  Array.isArray(secondaryStaticData) && !secondaryStaticData.length &&
    tagsonomyService.listTagsonomies(config.defaultThirdPartyThirdPartyParameters, onChangeTags);

  return (
    <div>
      {
        pages.filter(
          item => item.node.title === title
        )
          .map(
            ({ node: item, index }) =>
              [
                <GenericHeader
                  avatar={item.form.avatar}
                  hasButton
                  header={item.header}
                  innerConfig={config}
                  innerPages={pages}
                  navigate={navigate}
                  onChangeStep={onChangeStep}
                  step={step}
                  title={handleTitle()}
                />,
                hasCardContainer &&
                  <CardContainer
                    card={
                      <GenericCard
                        carouselContent={carouselContent}
                        data={data}
                        datatransfomation={item.datatransfomation}
                        externalStaticData={VowtchStatic}
                        form={item.form}
                        formValues={formValues}
                        grid={item.card.grid}
                        handleStaticData={handleStaticData}
                        hasHeader
                        headerWidgets={item.card.headerWidgets}
                        innerConfig={config}
                        innerPages={pages}
                        innerWidgets={item.card.innerWidgets}
                        sections={item.card.sections}
                        sideWidgets={item.card.sideWidgets}
                        staticData={staticData}
                        text={item.text}
                        title={handleTitle()}
                      />
                    }
                    innerConfig={config}
                    isMixinContent
                  >
                  </CardContainer>
              ]
          )
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeStep: (item) => dispatch (
      {
        payload: item,
        type: `onChangeStep`
      }
    )
    ,
    onChangeTags: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTags`
      }
    )
    ,
    receiveData: (payload) => dispatch (
      {
        payload,
        type: `receiveData`
      }
    )
    ,
    receiveDataAlternate: (payload) => dispatch (
      {
        payload,
        type: `receiveDataAlternate`
      }
    )
    ,
    receiveStaticData: (payload) => dispatch (
      {
        payload,
        type: `receiveStaticData`
      }
    )
  }
)

const mapStateToProps = state => {
  return {
    alternateCardInnerWidgetInnerWidgetShown: state.alternateCardInnerWidgetInnerWidgetShown,
    classieContentBadgeText: state.classieContentBadgeText,
    carouselContent: state.carouselContent,
    data: state.data,
    formValues: state.formValues,
    secondaryMenu: state.secondaryMenu,
    secondaryStaticData: state.secondaryStaticData,
    stateModified: state.stateModified,
    staticData: state.staticData,
    step: state.step
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  )
  (
    SearchBaseView
  );
