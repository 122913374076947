import React from 'react';

import PropTypes from 'prop-types';

import UsersBaseView from './UsersBaseView';

const UsersContactView = (
  props
) => {

  return (
    <div>
      <UsersBaseView
        {...props}
      >
      </UsersBaseView>
    </div>
  );
}

UsersContactView.propTypes = {

}

export default UsersContactView;
