import countries from "./countries.json";
import gbstates from "./gbstates.json";
import nobletitles from "./nobletitles.json";
import securityQuestions from "./securityquestions.json";
import usstates from "./usstates.json";

const VowtchStatic = {
  aliasTitle: nobletitles,
  country: countries,
  gbstate: gbstates,
  securityQuestion: securityQuestions,
  usstate: usstates
};

export default VowtchStatic;
