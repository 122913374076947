import React from 'react';

import PropTypes from 'prop-types';

import SearchBaseView from './SearchBaseView';

const SearchResultsView = (
  props
) => {

  return (
    <div>
      <SearchBaseView
        {...props}
      >
      </SearchBaseView>
    </div>
  );
}

SearchResultsView.propTypes = {

}

export default SearchResultsView;
