import React from 'react';

import PropTypes from 'prop-types';

import PublishingBaseView from './PublishingBaseView';

import './PublishingPreferencesView.less';

const PublishingPreferencesView = (
  props
) => {

  return (
    <div>
      <PublishingBaseView
        {...props}
      >
      </PublishingBaseView>
    </div>
  );
}

PublishingPreferencesView.propTypes = {

}

export default PublishingPreferencesView;
