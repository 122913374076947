import React from 'react';

import PropTypes from 'prop-types';

import PublishingBaseView from './PublishingBaseView';

import './PublishingPostView.less';

const PublishingContentTypesView = (
  props
) => {

  return (
    <div>
      <PublishingBaseView
        {...props}
      >
      </PublishingBaseView>
    </div>
  );
}

PublishingContentTypesView.propTypes = {

}

export default PublishingContentTypesView;
