import React from 'react';

import PropTypes from 'prop-types';

import PublishingBaseView from './PublishingBaseView';

// import './PublishingArticleView.less';

const PublishingDistributionView = (
  props
) => {

  return (
    <div>
      <PublishingBaseView
        {...props}
      >
      </PublishingBaseView>
    </div>
  );
}

PublishingDistributionView.propTypes = {

}

export default PublishingDistributionView;
