import React from 'react';

import { connect } from "react-redux";

import PropTypes from 'prop-types';

import { navigate } from "gatsby";

import Tagsonomy from 'tagsonomy-library';

import Vowtch from 'vowtch-library-branch';

import VowtchStatic from 'vowtch-static';

import config from '../../utils/config';

import './UsersBaseView.less';

const {
  tagsonomyService
} = Tagsonomy.Services;

const {
  CardContainer,
  GenericCard,
  GenericForm,
  GenericHeader
} = Vowtch.Components;

const {
  userService
} = Vowtch.Services;

const {
  guestStorageGetItem,
  guestStorageSetItem
} = Vowtch.Hooks;

const UserSecondaryBaseView = (
  {
    data,
    onChangeTags,
    receiveData,
    receiveFormValues,
    receiveStaticData,
    secondaryMenu,
    secondaryStaticData,
    staticData,
    subject,
    ...props
  }
) => {

  const  {
    hasCardContainer,
    pages,
    title
  } = props;

  const handleDataSubmit = (
    submitHandler,
    values
  ) => {
    Vowtch.Services[submitHandler.service][submitHandler.action](values, receiveFormValues);
  }


  !staticData.length &&
    userService.listUsers(config.defaultSecondaryParameters, receiveStaticData);

  Array.isArray(secondaryStaticData) && !secondaryStaticData.length &&
    tagsonomyService.listTagsonomies(config.defaultThirdPartySecondaryParameters, onChangeTags);

  console.log(secondaryStaticData)
  console.log(receiveFormValues)


  const handleTitle = (
    item = pages.filter(
      item => item.node.title === title
    ).slice().shift().node
  ) =>
    item.inheritTitle ?
      secondaryMenu ?
        secondaryMenu.items ?
          secondaryMenu.items.find(
            element => element.title.includes(item.card.title)
          ) ?
            secondaryMenu.items.find(
              element => element.title.includes(item.card.title)
            ).title
            :
            item.card.title
          :
          item.card.title
        :
        item.card.title
      :
      item.card.title
  ;

  !staticData.length &&
    // userService.listUsers(handleDefaultSecondaryParameters(), receiveStaticData);
    guestStorageGetItem(
      userService.listUsers,
      config.defaultParameters,
      receiveStaticData,
      config.entity.user.useStateWithLocalStorageKey
    );


  return (
    <div>
      {
        pages.filter(
          item => item.node.title === title
        )
          .map(
            ({ node: item, index }) =>
              [
                <GenericHeader
                  avatar={item.form.avatar}
                  datatransfomation={item.datatransfomation}
                  hasButton
                  header={item.header}
                  inheritTitle={item.inheritTitle}
                  innerConfig={config}
                  innerPages={pages}
                  navigate={navigate}
                  subject={subject}
                  thirdPartyTitle={item.thirdPartyTitle}
                  title={handleTitle()}
                />,
                hasCardContainer &&
                <CardContainer
                  card={
                    <GenericCard
                      datatransfomation={item.datatransfomation}
                      externalStaticData={VowtchStatic}
                      form={item.form}
                      grid={item.card.grid}
                      handleDataSubmit={handleDataSubmit}
                      hasButton
                      hasHeader
                      headerWidgets={item.card.headerWidgets}
                      innerConfig={config}
                      innerPages={pages}
                      innerWidgets={item.card.innerWidgets}
                      navigate={navigate}
                      navigation={item.navigation}
                      secondaryStaticData={secondaryStaticData}
                      sections={item.card.sections}
                      staticData={staticData}
                      table={item.table}
                      text={item.text}
                      title={item.card.title}
                    />
                  }
                  innerConfig={config}
                  isMixinContent
                />
              ]

          )
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeTags: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTags`
      }
    )
    ,
    receiveData: (payload) => dispatch (
      {
        payload,
        type: `receiveData`
      }
    )
    ,
    receiveFormValues: (payload) => dispatch (
      {
        payload,
        type: `receiveFormValues`
      }
    )
    ,
    receiveStaticData: (payload) => dispatch (
      {
        payload,
        type: `receiveStaticData`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    data: state.data,
    secondaryMenu: state.secondaryMenu,
    secondaryStaticData: state.secondaryStaticData,
    staticData: state.staticData,
    subject: state.subject
  }
);

const ConnectedUsersSecondaryBaseView = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSecondaryBaseView);

 export default ConnectedUsersSecondaryBaseView;
