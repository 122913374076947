import React from 'react';

import PropTypes from 'prop-types';

import PublishingBaseView from './PublishingBaseView';

const PublishingSplashView = (
  props
) => {

  return (
    <div>
      <PublishingBaseView
        {...props}
      >
      </PublishingBaseView>
    </div>
  );
}

PublishingSplashView.propTypes = {

}

export default PublishingSplashView;
